import { doc, getDoc, updateDoc } from "firebase/firestore";

export const generateInvoiceNumber = async (firestore) => {
  const newDate = new Date().toISOString().split("T")[0];
  const stateDocRef = doc(firestore, "project-state", "ADreipLmXLlVH0oLEEre"); // ID документа

  const stateDoc = await getDoc(stateDocRef);

  let invoiceNumber;
  if (stateDoc.exists()) {
    const lastInvoice = stateDoc.data()["last-invoice"];
    const [lastDate, lastNumber] = lastInvoice.split("/");

    // console.log(lastDate);
    // console.log(newDate);

    if (lastDate === newDate) {
      const newNumber = parseInt(lastNumber) + 1;
      invoiceNumber = `${newDate}/${newNumber}`;
      await updateDoc(stateDocRef, { "last-invoice": invoiceNumber });
    } else {
      invoiceNumber = `${newDate}/1`;
      await updateDoc(stateDocRef, { "last-invoice": invoiceNumber });
    }
  } else {
    invoiceNumber = `${newDate}/1`;
    await updateDoc(stateDocRef, { "last-invoice": invoiceNumber });
  }

  return invoiceNumber;
};
