import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { useScrollPosition } from "../../utils/dom/scroll";
import "./burgerStyle.scss";

export const Burger = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headerResize = useScrollPosition();
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null); // Змінено для одночасного відкриття тільки одного меню
  const menuRefs = useRef([]); // Використання useRef для зберігання посилань на меню

  const handleMenuClick = (event) => {
    const { type, id, key } = event.currentTarget.dataset;
    if (type === "course") {
      navigate(`/${key}`);
      setShowBurgerMenu(false);
    } else if (type === "navigate") {
      navigate(`/${id}`);
      setShowBurgerMenu(false);
    }
  };

  const toggleMenu = (index) => {
    setMenuOpen((current) => (current === index ? null : index)); // Змінено для одночасного відкриття тільки одного меню
  };

  useEffect(() => {
    menuRefs.current.forEach((menuRef, index) => {
      if (menuRef) {
        menuRef.style.setProperty("--max-height", `${menuRef.scrollHeight}px`);
      }
    });
  }, [menuOpen]);

  const menuData = [
    {
      title: "courses",
      items: [
        { id: "1", key: "lang_dutch_course" },
        { id: "2", key: "integration_course" },
        { id: "3", key: "pro_hotel" },
        { id: "4", key: "pro_restaurant" },
        { id: "7", key: "pro_retail" },
        { id: "5", key: "pro_logistics" },
        { id: "8", key: "pro_food_industry" },
        { id: "6", key: "pro_entrepreneurship" },
      ].map((course) => ({ ...course, type: "course" })),
    },
    {
      title: "contacts",
      items: [
        { key: "email_address", text: "inacademyMBO.post@gmail.com" },
        {
          key: "address",
          text: t("address_mbo"),
        },
      ],
    },
  ];

  return (
    <>
      <div
        className={`burger ${showBurgerMenu ? "burger--open" : ""}`}
        onClick={() => setShowBurgerMenu(!showBurgerMenu)}
      >
        <div className="burger__line"></div>
        <div className="burger__line"></div>
        <div className="burger__line"></div>
      </div>

      <section
        className={`burger__container ${
          showBurgerMenu ? "burger__container--open" : ""
        } ${headerResize ? "" : "burger__container--short"}`}
      >
        {menuData.map((menu, index) => (
          <div key={index} className="burger__menu-item">
            <div
              className="burger__menu-title"
              onClick={() => toggleMenu(index)}
            >
              {t(menu.title)}
            </div>
            <CSSTransition
              in={menuOpen === index}
              timeout={300}
              classNames="menu-slide"
              unmountOnExit
              nodeRef={menuRefs.current[index]} // Використання nodeRef
            >
              <div
                className="menu-content"
                ref={(el) => (menuRefs.current[index] = el)}
              >
                {menu.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="burger__menu-element"
                    data-type={item.type || "info"}
                    data-id={item.id}
                    data-key={item.key}
                    onClick={handleMenuClick}
                  >
                    {item.text ? `${t(item.key)}: ${item.text}` : t(item.key)}
                  </div>
                ))}
              </div>
            </CSSTransition>
          </div>
        ))}

        <div
          className="burger__menu-item"
          data-type="navigate"
          data-id="faq"
          onClick={handleMenuClick}
        >
          <div className="burger__menu-title">{t("faq")}</div>
        </div>

        <div
          className="burger__menu-item"
          data-type="navigate"
          data-id="news"
          onClick={handleMenuClick}
        >
          <div className="burger__menu-title">{t("news")}</div>
        </div>

        <div
          className="burger__menu-item"
          data-type="navigate"
          data-id="about_us"
          onClick={handleMenuClick}
        >
          <div className="burger__menu-title">{t("about_us")}</div>
        </div>
      </section>
    </>
  );
};
