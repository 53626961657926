export const openHandler = () => {
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  // перевіряєм що ми не запущені на мобільному телефоні
  if (!isMobileDevice) {
    document.body.style.paddingRight = `7px`;
    document.querySelector(".scrollBtn").style.right = `62px`;
    document.querySelector(".headers").style.right = `7px`;
  }
  // блокуєм прокрутку
  document.body.classList.add("body-no-scroll");
};

export const closeHandler = () => {
  document.body.style.paddingRight = ``;

  const scrollBtn = document.querySelector(".scrollBtn");
  const headers = document.querySelector(".headers");

  if (scrollBtn) {
    scrollBtn.style.right = ``;
  }

  if (headers) {
    headers.style.right = ``;
  }

  // розблоковуєм прокрутку
  document.body.classList.remove("body-no-scroll");
};
