import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { Context } from "../../../index";
import bg from "../../../lib/bg/pic-2a.jpg";
import "./sliderStyle.scss";

export const Slider = () => {
  const { auth } = useContext(Context);
  const [user] = useAuthState(auth);

  return (
    <section className="slider">
      <article className="slider__title">
        <p className="slider__title-text">International Academy</p>
        <p className="slider__title-text">MBO</p>
        {user ? "" : <SubmitBtn />}
      </article>
      <div className="slider__image-container">
        <img src={bg} className="slider__image" alt="Slider Background" />
        <div className="slider__overlay"></div>
      </div>
    </section>
  );
};

const SubmitBtn = () => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const shadowRef = useRef(null);

  const handleGetCourseModal = () => {
    const targetElement = document.getElementById("login-btn"); // Отримуємо елемент за ID
    if (targetElement) {
      targetElement.click(); // Виконуємо клік на елементі
    } else {
      console.warn("Елемент з id 'login-btn' не знайдено.");
    }
  };

  // фікс ширини кнопки
  useEffect(() => {
    const updateButtonShadowWidth = () => {
      if (buttonRef.current && shadowRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;
        shadowRef.current.style.width = `${buttonWidth - 3}px`; // Adjust shadow width dynamically
      }
    };
    updateButtonShadowWidth();
  }, [t]);

  return (
    <div className="customSubmitButton" onClick={handleGetCourseModal}>
      <button className="customSubmitButton__btn" type="submit" ref={buttonRef}>
        <span className="customSubmitButton__span">
          {t("submit_questionnaire")}
        </span>
      </button>
      <div className="customSubmitButton__shadow" ref={shadowRef}></div>
    </div>
  );
};
