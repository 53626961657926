import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";
import { toast } from "react-toastify";
import { generateInvoiceNumber } from "../../utils/network/generateInvoiceNumber";

import contractFile from "../../database/contract.docx";
import invoiceFile from "../../database/invoice.docx";

export async function generateDocument(
  userData,
  documentType,
  firestore,
  callback
) {
  const file = documentType === "invoice" ? invoiceFile : contractFile;

  JSZipUtils.getBinaryContent(file, async (error, content) => {
    if (error) {
      console.error("Помилка при завантаженні файлу: ", error);
      toast.error("Помилка при завантаженні файлу");
      return;
    }

    const newDate = new Date().toISOString().split("T")[0];

    try {
      const invoiceNumber =
        documentType === "invoice"
          ? await generateInvoiceNumber(firestore)
          : null;

      const zip = new PizZip(content);
      const doc = new Docxtemplater().loadZip(zip);

      // Встановлення даних
      const data = {
        displayName: userData.displayName,
        selectedCourse: userData.selectedCourse,
        phoneNumber: userData.phoneNumber,
        email: userData.email,
        fullAddress: userData.fullAddress,
        birthDate: userData.birthDate,

        payerName: userData.relativeDisplayName || userData.displayName,
        payerAddress: userData.relativeFullAddress || userData.fullAddress,
        payerEmail: userData.relativeEmail || userData.email,
        amountDue: "87.00",
        invoiceNumber: invoiceNumber,
        invoiceDate: newDate,
      };

      doc.setData(data);
      doc.render();

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      console.log("Generated document blob:", out); // Додаємо перевірку згенерованого блоба

      if (documentType === "invoice" && callback) {
        callback(out); // Передаємо Blob у зворотний виклик
      } else {
        FileSaver.saveAs(out, `${documentType}.docx`);
        toast.success("Файл успішно згенеровано");
      }
    } catch (error) {
      const e = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        properties: error.properties,
      };
      console.log("Rendering error:", JSON.stringify({ error: e })); // Додаємо перевірку помилок
      toast.error(`Помилка: ${error.message}`);
    }
  });
}
