import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./spinnerStyle.scss";
import spinGif from "../../lib/MBOSpinner2.gif";
import errorImg from "../../lib/logo-icons/errorImg2.png";

export const Spinner = ({ type = "app", size = "large", overlay = true }) => {
  const [countdown, setCountdown] = useState(10);
  const [failed, setFailed] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Функція для перевірки стану мережі

    const countdownTimer = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        setFailed(true);
        if (navigator.onLine) {
          // Якщо користувач в онлайні, показуємо помилку і перенаправляємо на головну сторінку
          console.log(`Error. Cannot get to ${type}`);
          toast.error(t("Error. Try again later"));
          // window.location.reload();
        } else {
          // Якщо користувач в офлайні, показуємо повідомлення про перевірку з'єднання
          toast.error(t("You are offline. Check your internet connection."));
        }
      }
    }, 1000);

    // Очищуємо таймер, коли компонент буде знищено або при кожному оновленні таймера
    return () => clearTimeout(countdownTimer);
  }, [countdown, type, t]);

  return (
    <section
      className={`spinner-container ${size} ${overlay ? "overlay" : ""}`}
    >
      {failed ? (
        <>
          <img className="spinner-image" src={errorImg} alt="error-img" />
          <p className="spinner-container__message spinner-container__message--red">
            {t("error_occurred")}
          </p>

          <div className="spinner-container__wrap">
            <button
              className="spinner-container__btn"
              onClick={() => {
                window.location.reload();
              }}
            >
              {t("reload_page")}
            </button>

            <button
              className="spinner-container__btn"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("return_to_main_page")}
            </button>
          </div>
        </>
      ) : (
        <>
          <img className="spinner-image" src={spinGif} alt="Loading..." />
          <p className="spinner-container__message">
            {t("loading")} {type}
          </p>
          <p className="spinner-container__message">
            {countdown} {t("seconds")}
          </p>
        </>
      )}
    </section>
  );
};
