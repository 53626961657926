import React, { useContext, useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { closeHandler } from "../../../utils/dom/open-close-modal";
import { DownloadFile } from "../../../utils/fileHandlers/downloadFile";
import { Context } from "../../../index";
import { toast } from "react-toastify";

import Select from "react-select";
import countryList from "react-select-country-list";
import { setDoc, doc } from "firebase/firestore";
import {
  createUserProgress,
  SendUserDataToBackend,
  updateCurrentUserProfile,
} from "../../../utils/network/userActions";
import { SendRegistrationEmail } from "../../../utils/network/networkActions";
import defaultPhoto from "../../../lib/noname-ava2.png";

export const RegisterTabComponent = ({ setShowLoginModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth, firestore, storage } = useContext(Context);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, confirmConfirmPassword] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [workPlace, setWorkPlace] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("dutch_language_course");
  const [permission, setPermission] = useState(false);
  const [isOffer, setIsOffer] = useState(false);

  const [below18, setBelow18] = useState(false);
  const [relativeFirstName, setRelativeFirstName] = useState("");
  const [relativeLastName, setRelativeLastName] = useState("");
  const [relativeBirthDate, setRelativeBirthDate] = useState("");
  const [relativeAddress, relativeSetAddress] = useState("");
  const [relativeFullAddress, relativeSetFullAddress] = useState("");
  const [relativeEmail, setRelativeEmail] = useState("");
  const [relativePhoneNumber, setRelativePhoneNumber] = useState("");
  const [relativeSelectedCountry, setRelativeSelectedCountry] = useState(null);

  // const [bsn, setBsn] = useState("");

  const handleCloseModal = (e) => {
    if (e && e.target && e.target.classList.contains("modal-overlay")) {
      setShowLoginModal(false);
    }
    setShowLoginModal(false);
    closeHandler();
  };

  useEffect(() => {
    const options = countryList().getData();
    setCountries(options);
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();
    // перевіряєм форму на помилки
    if (!validateForm()) return;
    try {
      // логінимся на сайт
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // оновлюємо профіль на сайті
      const user = userCredential.user;
      await updateCurrentUserProfile(
        auth,
        user,
        firstName,
        lastName,
        defaultPhoto
      );

      // костиль для relativeFullAddress
      // щоб не давало undefined
      let relativeFullAddress = "";
      if (relativeSelectedCountry?.label && relativeAddress) {
        relativeFullAddress = `${relativeSelectedCountry.label}, ${relativeAddress}`;
      }

      // костиль для relativeDisplayName
      // щоб не давало пробела
      let relativeDisplayName = "";
      if (relativeFirstName && relativeLastName) {
        relativeDisplayName = `${relativeFirstName} ${relativeLastName}`;
      }

      // формуємо пакет для відправки
      const userData = {
        displayName: firstName + " " + lastName,
        uid: user.uid,
        premium: false,
        email,
        photoURL: "",
        birthDate,
        phoneNumber,
        fullAddress: selectedCountry.label + ", " + address,

        relativeDisplayName: relativeDisplayName,
        relativeBirthDate,
        relativeEmail,
        relativePhoneNumber,
        relativeFullAddress: relativeFullAddress,
      };

      // зберігаємо дані користувача в Firestore з використанням UID як ідентифікатора
      await setDoc(doc(firestore, "users", user.uid), userData);

      // створюємо початковий прогрес студента
      await createUserProgress(userData, firestore);

      // відправляємо дані про нового користувача на firebase
      // await SendUserDataToBackend(userData, firestore, t);

      // відправляємо електронного листа новому користувачу
      await SendRegistrationEmail(userData, firestore, storage);

      //надсилаємо повідомлення в Discord
      // sendDataToDiscord(userData);

      toast.success(t("registration_success"));

      handleCloseModal(); // закриваємо модальне вікно
    } catch (error) {
      toast.error(t("error_occurred"));
      console.error(error);
    } // Обробка помилок реєстрації
  };

  const handleInput = (value, setter, pattern = /^[a-zA-Z0-9\s.@,\/-]*$/) => {
    if (pattern.test(value) || value === "") {
      setter(value);
    }
  };

  const validateForm = () => {
    if (!firstName.trim() || !lastName.trim()) {
      toast.error(t("enter_full_name"));
      return false;
    }
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailPattern.test(email.trim())) {
      toast.error(t("enter_valid_email"));
      return false;
    }
    if (password.length < 6) {
      toast.error(t("password_minimum_length"));
      return false;
    }
    if (password !== confirmPassword) {
      toast.error(t("passwords_do_not_match"));
      return false;
    }
    if (!permission) {
      toast.error(t("confirm_personal_data_processing_consent"));
      return false;
    }
    if (!selectedCountry) {
      toast.error(t("select_country"));
      return false;
    }
    const phonePattern = /^\+?\d{10,15}$/;
    if (!phonePattern.test(phoneNumber.trim())) {
      toast.error(t("enter_valid_phone_number"));
      return false;
    }
    return true;
  };

  const handleBirthDateInput = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length >= 2) {
      value = value.slice(0, 2) + "." + value.slice(2);
    }
    if (value.length >= 5) {
      value = value.slice(0, 5) + "." + value.slice(5, 9);
    }
    setBirthDate(value);
  };

  return (
    <form className="loginForm" onSubmit={handleSignUp}>
      <div className="col center">
        <p className="loginForm__text--bold">
          {t("student_registration_form")}
        </p>
        <p>({t("form_1")})</p>

        <p className="loginForm__text--italic">{t("information_in_latin")}</p>
      </div>

      <div className="loginForm__row">
        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="firstName">
            {t("name")}
          </label>
          <input
            className="loginForm__input"
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => handleInput(e.target.value, setFirstName)}
            placeholder={t("name")}
            required
          />
        </div>

        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="lastName">
            {t("surname")}
          </label>
          <input
            className="loginForm__input"
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => handleInput(e.target.value, setLastName)}
            placeholder={t("surname")}
            required
          />
        </div>
      </div>

      <div className="loginForm__col">
        <label className="loginForm__label" htmlFor="email">
          {t("email_address")}
        </label>
        <input
          className="loginForm__input"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("email_address")}
          required
        />
      </div>

      <div className="loginForm__row">
        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="password">
            {t("password")}
          </label>
          <input
            className="loginForm__input"
            id="password"
            type="password"
            value={password}
            onChange={(e) => handleInput(e.target.value, setPassword)}
            placeholder={t("password")}
            required
          />
        </div>

        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="confirm-password">
            {t("confirm_password")}
          </label>
          <input
            className="loginForm__input"
            id="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) =>
              handleInput(e.target.value, confirmConfirmPassword)
            }
            placeholder={t("confirm_password")}
            required
          />
        </div>
      </div>

      <div className="loginForm__col">
        <label htmlFor="country">{t("country_of_residence")}:</label>
        <Select
          options={countries}
          value={selectedCountry}
          onChange={setSelectedCountry}
          id="country"
        />
      </div>

      <div className="loginForm__col">
        <label className="loginForm__label" htmlFor="address">
          {t("address")}
        </label>
        <input
          id="address"
          className="loginForm__input"
          type="text"
          value={address}
          onChange={(e) => handleInput(e.target.value, setAddress)}
          placeholder={t("address_fields")}
          required
        />
      </div>

      {/* 
      <div className="loginForm__col">
        <label className="loginForm__label" htmlFor="place_of_work">
          {t("place_of_work")} {"  ("} {t("optional")} {")"}
        </label>
        <input
          id="place_of_work"
          className="loginForm__input"
          type="text"
          value={workPlace}
          onChange={(e) => handleInput(e.target.value, setWorkPlace)}
          placeholder={t("place_of_work")}
        />
      </div> */}

      <div className="loginForm__row">
        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="birthdate">
            {t("date_of_birth")}
          </label>
          <input
            id="birthdate"
            className="loginForm__input"
            type="text"
            value={birthDate}
            onChange={handleBirthDateInput}
            placeholder="dd.mm.yyyy"
            required
          />
        </div>

        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="phoneNumber">
            {t("phone_number")}
          </label>
          <input
            className="loginForm__input"
            type="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+380995554433"
            required
          />
        </div>
      </div>

      <div className="permission__wrap">
        {below18 ? (
          <section className="permission__container">
            <div className="permission">
              <input
                className="permission__input"
                type="checkbox"
                id="below18"
                checked={below18}
                onChange={(e) => setBelow18(e.target.checked)}
              />
              <label
                className="permission__label permission__underlined"
                htmlFor="below18"
              >
                {t("under_18")}
              </label>
            </div>

            <div className="col center">
              <p className="loginForm__text--bold">
                {t("representative_registration_form")}
              </p>
              <p>({t("form_2")})</p>
            </div>

            <p className="permission__warning">
              {t("filled_by_parents_or_legal_representatives")}. {"  "}
              {t("information_in_latin")}
            </p>

            <div className="loginForm__row">
              <div className="loginForm__col">
                <label className="loginForm__label" htmlFor="relativefirstName">
                  {t("name")}
                </label>
                <input
                  className="loginForm__input"
                  type="text"
                  id="relativefirstName"
                  value={relativeFirstName}
                  onChange={(e) => setRelativeFirstName(e.target.value)}
                  placeholder={t("name")}
                  required={below18}
                />
              </div>

              <div className="loginForm__col">
                <label className="loginForm__label" htmlFor="relativelastName">
                  {t("surname")}
                </label>
                <input
                  className="loginForm__input"
                  type="text"
                  id="relativelastName"
                  value={relativeLastName}
                  onChange={(e) => setRelativeLastName(e.target.value)}
                  placeholder={t("surname")}
                  required={below18}
                />
              </div>
            </div>

            <div className="loginForm__col">
              <label className="loginForm__label" htmlFor="relativeEmail">
                {t("email_address")}
              </label>
              <input
                className="loginForm__input"
                type="email"
                id="relativeEmail"
                value={relativeEmail}
                onChange={(e) => setRelativeEmail(e.target.value)}
                placeholder={t("email_address")}
                required
              />
            </div>

            <div className="loginForm__col">
              <label htmlFor="country">{t("country_of_residence")}:</label>
              <Select
                options={countries}
                value={relativeSelectedCountry}
                onChange={setRelativeSelectedCountry}
                id="country"
              />
            </div>

            <div className="loginForm__col">
              <label className="loginForm__label" htmlFor="relativeAddress">
                {t("address")}
              </label>
              <input
                id="relativeAddress"
                className="loginForm__input"
                type="text"
                value={relativeAddress}
                onChange={(e) =>
                  handleInput(e.target.value, relativeSetAddress)
                }
                placeholder={t("address_fields")}
                required
              />
            </div>

            <div className="loginForm__row">
              <div className="loginForm__col">
                <label className="loginForm__label" htmlFor="relativeBirthdate">
                  {t("date_of_birth")}
                </label>
                <input
                  id="relativeBirthdate"
                  className="loginForm__input"
                  type="text"
                  value={relativeBirthDate}
                  onChange={(e) => setRelativeBirthDate(e.target.value)}
                  placeholder={t("date_of_birth")}
                  required
                />
              </div>

              <div className="loginForm__col">
                <label
                  className="loginForm__label"
                  htmlFor="relativePhoneNumber"
                >
                  {t("phone_number")}
                </label>
                <input
                  className="loginForm__input"
                  type="phoneNumber"
                  id="relativePhoneNumber"
                  value={relativePhoneNumber}
                  onChange={(e) => setRelativePhoneNumber(e.target.value)}
                  placeholder="+380995554433"
                  required
                />
              </div>
            </div>
          </section>
        ) : (
          <div className="permission">
            <input
              className="permission__input"
              type="checkbox"
              id="below18"
              checked={below18}
              onChange={(e) => setBelow18(e.target.checked)}
            />
            <label className="permission__label" htmlFor="below18">
              {t("under_18")}
            </label>
          </div>
        )}

        <div className="permission">
          <input
            className="permission__input"
            type="checkbox"
            id="permission"
            checked={permission}
            onChange={(e) => setPermission(e.target.checked)}
            required
          />
          <label className="permission__label" htmlFor="permission">
            {t("consent_to_process_personal_data")}
          </label>
        </div>

        <div className="permission">
          <input
            className="permission__input"
            type="checkbox"
            id="isOffer"
            checked={isOffer}
            onChange={(e) => setIsOffer(e.target.checked)}
            required
          />
          <label className="permission__label" htmlFor="isOffer">
            {t("agreement_acknowledgment")} ({t("public_offer")})
          </label>
        </div>
      </div>

      <button type="submit" className="loginForm__btn">
        {t("register")}
      </button>

      <div className="loginForm__offer">
        <DownloadFile />
      </div>
    </form>
  );
};
