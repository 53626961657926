import "./suspenseSpinnerStyle.scss";
import spinGif from "../../lib/MBOSpinner2.gif";

export const SuspenseSpinner = () => {
  return (
    <section className="suspenseOverlay">
      {/* <div className="suspenseSpinner"></div> */}
      <img className="spinner-image" src={spinGif} alt="Loading..." />
    </section>
  );
};
